<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <validation-observer ref="userRules">
      <b-card class="mt-2">
        <b-row class="mb-2">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">General Web Site Settings</th>
              </tr>
            </thead>
          </table>
        </b-row>
      </b-card>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { BProgress, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import store from '@/store';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, alphaNum, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ProfileCropper from '@core/components/image-cropper/ProfileCropper.vue';
import axiosIns from '@/libs/axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import settingsStoreModule from '../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ValidationProvider,
    ValidationObserver,
    BProgress,
    ProfileCropper,
  },
  directives: {
    Ripple,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const refInputEl = ref(null);
    const previewEl = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },

  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      imageUrl: require('@/assets/images/logo/loading.gif'),
      tempImage: null,
      settingURL: store.state.app.settingURL,
      selectedImage: null,
      croppedImage: null,
      percentCompleted: 0,
      formShow: false,
      setting: {
        homeTitle: '',
        logo: '',
        title: '',
      },
      mainProps: {
        blank: false,
        blankColor: '#777',
        width: 200,
        height: 30,
        class: 'm1',
      },
      required,
    };
  },
  methods: {
    imageSelected(event) {
      const file = event.target.files ? event.target.files[0] : null;

      if (file != null) {
        this.getBase64(file).then((data) => {
          this.selectedImage = data;
        });
      }
    },
    imageCropped(croppedImage) {
      if (croppedImage != null) {
        this.formShow = true;
        let formData = new FormData();
        formData.append('file', croppedImage);
        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }.bind(this),
        };
        axiosIns
          .post('cropWebsiteLogo', formData, config)
          .then((response) => {
            this.setting.logo = response.data;
            this.imageUrl = this.settingURL + response.data;

            this.tempImage = null;
            this.selectedImage = null;
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Successful',
                text: '✔️ Image Cropped Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            console.log(error);
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Not Successful',
                text: '⛔ Image Upload Not Successful',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.tempImage = null;
        this.selectedImage = null;
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    updateSetting() {
      this.formShow = true;
      store
        .dispatch('settings/updateSetting', this.setting)
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              title: 'Success!',
              text: 'Website settings has been updated!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
          }
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          this.$swal({
            title: 'Error!',
            text: 'User Could not be updated',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });

          console.log(error);
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.userRules.validate().then((success) => {
          if (success) {
            this.updateSetting();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Christ no!',
                text: '📝 Required fields cannot be left blank',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            });
            //    reject();
          }
        });
      });
    },
  },
  created() {
    store
      .dispatch('settings/fetchSettings')
      .then((response) => {
        this.setting = {
          homeTitle: response.data.homeTitle,
          logo: response.data.logo,
          title: response.data.title,
        };
        this.imageUrl = this.settingURL + response.data.logo;

        this.formShow = false;
      })
      .catch((error) => {
        this.formShow = false;
        this.$swal({
          title: 'Error!',
          text: 'Password could not be changed',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
        console.log(error);
      });
  },
};
</script>

<style scoped>
.uploadButton {
  position: absolute;
  top: -8px;
  right: 85px;
  margin: 2px;
  padding: 3px;
  border-radius: 6px;
  color: #fff;
  background-color: #ff0000;
  cursor: pointer;
  opacity: 0.5;
}
.uploadButton:hover {
  opacity: 1;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
</style>
